<template>
  <ModuleComponent
    ref="moduleComponent"
    @changed="item = $event"
    :module-data="moduleData"
    @beforeSaveItem="beforeSaveItem"
    @beforeItemLoad="beforeItemLoad"
  >
    <template v-slot:customColumn_active="{ rowItem }">
      <TableStatusColumn v-model="rowItem.active"></TableStatusColumn>
    </template>
    <template v-slot:tableFilters="{ filters }">
      <b-container fluid>
        <b-row>
          <b-col cols="3" sm="3" md="3">
            <b-form-group :label="t('GLOBAL.NAME')">
              <b-form-input
                name="name"
                v-model="filters.name.value"
                :placeholder="t('GLOBAL.NAME')"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="3" sm="6" md="3">
            <b-form-group :label="t('GLOBAL.STATUS')" class="mb-3">
              <vue-select
                v-model="filters.active.value"
                label="text"
                :options="[
                  { text: t('GLOBAL.ACTIVE'), value: true },
                  { text: t('GLOBAL.PASSIVE'), value: false }
                ]"
                :multiple="false"
                :placeholder="t('GLOBAL.ALL')"
              >
              </vue-select>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </template>

    <template v-slot:form="{ item }">
      <b-tabs class="mt-3">
        <b-tab :title="t('GLOBAL.INFO')" active>
          <b-row>
            <b-col cols="12" sm="6" md="4">
              <b-form-group :label="t('GLOBAL.ROLE')">
                <b-form-input v-model="item.name"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="2" sm="3" md="2">
              <b-form-group :label="t('GLOBAL.STATUS')">
                <b-form-checkbox v-model="item.active" switch>
                  {{ t("GLOBAL.ACTIVE") }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab :title="t('GLOBAL.ROLEMODULES')">
          <b-button @click="togglePermissions" class="mr-1" size="sm"
            >Toggle Permissions
          </b-button>
          <b-button @click="setAll(true)" class="mr-1" size="sm"
            >Tümüne izin ver
          </b-button>
          <b-button @click="setAll(false)" class="mr-1" size="sm"
            >Tüm izinleri kaldır
          </b-button>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">{{ t("GLOBAL.MODULE") }}</th>
                  <th class="text-center"></th>
                  <th class="text-center">{{ t("GLOBAL.VISIBLE") }} (GET)</th>
                  <th class="text-center">{{ t("GLOBAL.GET") }} (GET)</th>
                  <th class="text-center">{{ t("GLOBAL.POST") }} (POST)</th>
                  <th class="text-center">{{ t("GLOBAL.PUT") }} (PUT)</th>
                  <th class="text-center">{{ t("GLOBAL.DELETE") }} (DELETE)</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(p, index) in permissionsMap" :key="p.moduleId">
                  <td v-if="p.module">{{ p.module.name }}</td>
                  <td class="text-center">
                    <b-button size="sm" @click="setRow(index, p.get)">
                      Tümünü Aç/Kapa
                    </b-button>
                  </td>
                  <td class="text-center">
                    <b-form-checkbox v-model="p.visible" switch
                      >VISIBLE</b-form-checkbox
                    >
                  </td>
                  <td class="text-center">
                    <b-form-checkbox
                      v-model="p.get"
                      @change="setPermission(index, !p.get)"
                      switch
                      >GET
                    </b-form-checkbox>
                  </td>
                  <td class="text-center">
                    <b-form-checkbox v-model="p.post" switch :disabled="!p.get"
                      >POST</b-form-checkbox
                    >
                  </td>
                  <td class="text-center">
                    <b-form-checkbox v-model="p.put" switch :disabled="!p.get"
                      >PUT
                    </b-form-checkbox>
                  </td>
                  <td class="text-center">
                    <b-form-checkbox
                      v-model="p.delete"
                      switch
                      :disabled="!p.get"
                      >DELETE
                    </b-form-checkbox>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </b-tab>
      </b-tabs>
    </template>
  </ModuleComponent>
</template>

<script>
// http://core.datadns.net:5000/swagger/index.html#model-Role
export default {
  name: "roles",
  data() {
    return {
      item: {},
      moduleData: {
        name: "roles",
        primaryKey: "id",
        table: {
          sortBy: "name",
          sortDesc: false,
          graphqlQuery: `id,name,active`,
          headers: [
            { text: "ID", value: "id", width: "70" },
            { text: this.t("GLOBAL.NAME"), value: "name" },
            {
              text: this.t("GLOBAL.STATUS"),
              value: "active",
              width: "60",
              align: "center"
            }
          ],
          customColumns: ["active"],
          filters: {
            name: {
              type: "string",
              searchBy: "beginsWith",
              value: null
            },
            active: {
              type: "bool",
              value: null
            }
          }
        }
      },
      allModules: [],
      permissionsMap: []
    };
  },
  methods: {
    setRow(index, status) {
      this.permissionsMap[index].get = !status;
      this.permissionsMap[index].post = !status;
      this.permissionsMap[index].put = !status;
      this.permissionsMap[index].delete = !status;
      this.permissionsMap[index].visible = !status;
    },
    setPermission(index, status) {
      this.permissionsMap[index].post = !status;
      this.permissionsMap[index].put = !status;
      this.permissionsMap[index].delete = !status;
      this.permissionsMap[index].visible = !status;
    },
    togglePermissions() {
      this.permissionsMap.forEach(p => {
        p.get = !p.get;
        p.delete = !p.delete;
        p.visible = !p.visible;
        p.post = !p.post;
        p.put = !p.put;
      });
    },
    setAll(status) {
      this.permissionsMap.forEach(p => {
        p.get = status;
        p.delete = status;
        p.visible = status;
        p.post = status;
        p.put = status;
      });
    },
    beforeSaveItem(item) {
      item.roleModules = this.permissionsMap.map(p => {
        delete p["module"];
        return p;
      });
    },
    beforeItemLoad(item) {
      this.permissionsMap = this.getPermissions(item);
    },
    getPermissions(item) {
      let roleModules = [];
      for (const i in this.allModules) {
        let module = this.allModules[i];
        let found = false;
        for (const j in item.roleModules) {
          if (item.roleModules[j].moduleId == module.id) {
            roleModules.push(item.roleModules[j]);
            found = true;
            break;
          }
        }
        if (!found) {
          roleModules.push({
            moduleId: module.id,
            roleId: this.item.id,
            module: {
              name: module.name
            },
            post: false,
            get: false,
            put: false,
            delete: false,
            visible: false
          });
        }
      }
      return roleModules;
    }
  },
  mounted() {
    this.apiSendGraph("modules", "id,name", "name").then(data => {
      this.allModules = data.data;
    });
  }
};
</script>
